export default class ScrollTo {

  constructor() {
    this.scrollButton = document.querySelectorAll('.js-scroll');
    this.dataElId = 'data-target';

    if (this.scrollButton != null) {
      this.bindEvents();
    }
  }

  bindEvents() {
    this.scrollButton.forEach(el => {

      el.addEventListener('click', () => {
        this.scroll(el);
      });

    });
  }

  scroll(el) {
    let id = el.getAttribute(this.dataElId);
    id = document.querySelector(id);

    const offset = 65;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = id.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  }

}