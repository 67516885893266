/**
 * Instantiate MobileNavigation
 * @param  {Node}       wrapper Dom class or dom node
 * @param  {Object}     options Object holding optional parameters
 * @return {undefined}
 */
export default class MobileNavigation {
   constructor({ burger, dropdown, overlay, menu, options = {} }) {
      this.header = document.querySelector('.header')
      this.burgerDOM = document.querySelector(burger)
      this.dropdownDOM = document.querySelectorAll(dropdown)
      // this.overlayDOM = document.querySelector(overlay)
      this.menuDOM = document.querySelector(menu)

      this._bindEvents()
   }

   _bindEvents() {
      this.dropdownDOM.forEach(el => {
         el.addEventListener("click", () => this._openmenu(el))
      });

      this.burgerDOM.addEventListener("click", () => this._activate())
      // this.itemsDOM.forEach(item => item.addEventListener("click", this.boundActivateEvent))
   }
   _openmenu(el) {
      var parent = el.parentElement;
      parent.classList.toggle('is-active');
      console.log(parent);
   }
   _activate(event) {
      if (this.burgerDOM.classList.contains("is-active")) {
         this.burgerDOM.classList.remove("is-active")
         this.menuDOM.classList.remove("is-active")
         this.header.classList.remove("open")
         // if(this.overlayDOM) this.overlayDOM.classList.remove("is-active")
      } else {
         this.burgerDOM.classList.add("is-active")
         this.menuDOM.classList.add("is-active")
         this.header.classList.add("open")
         // if(this.overlayDOM) this.overlayDOM.classList.add("is-active")
      }
   }
}