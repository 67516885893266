import "../styles/main.scss";

import MobileNavigation from "./modules/mobile";
import ScrollTo from "./modules/scrollto";

const lazyload = new LazyLoad({
   elements_selector: ".lazy"
});

new MobileNavigation({
   burger: ".js-mobile",
   menu: ".js-menu",
   dropdown: ".dropdown-icon",
});

new ScrollTo();

window.onscroll = function (){ move_header(); };

function move_header()
{
  var header = document.querySelector('.header');
  var sticky = header.offsetTop;

  if (window.pageYOffset > sticky) {
    header.classList.add('in-move');
  } else {
    header.classList.remove('in-move');
  }
}

/**
 * Main Slider
 */
try {

const swiper = new Swiper('.slider', {
  loop: false,
  pagination: {
      el: '.slide-pagination',
      bulletClass: 'slide-bullet',
      bulletActiveClass: 'slide-bullet-active',
      clickable: true,
      renderBullet: function renderBullet(index, className) {
        return '<li class="' + className + '"><span class="slide-dot"></span></li>';
      }
  },
});

} catch (error) {}

try {
  // review slider hompage
  const swiper_review = new Swiper(".review-slider", {
    slidesPerView: 1,
    centeredSlides: true,
    spaceBetween: 30,
    grabCursor: true,
    initialSlide: 1,
    loop: false,
    navigation: {
        nextEl: ".review-button-next",
        prevEl: ".review-button-prev",
    },
    breakpoints: {
    992: {
        slidesPerView: 2,
    }
    }
  });
} catch (error) {

}

ScrollReveal().reveal('.scroll-top', { origin: 'top', distance: '150px', delay: 400, reset: false, duration: 1500});
ScrollReveal().reveal('.scroll-bottom', { origin: 'bottom', distance: '150px', delay: 400, reset: false, duration: 1500});
ScrollReveal().reveal('.scroll-left', { origin: 'left', distance: '150px', delay: 400, reset: false, duration: 1500});
ScrollReveal().reveal('.scroll-right', { origin: 'right', distance: '150px', delay: 400, reset: false, duration: 1500 });


var timeout;
jQuery( function( $ ) {
    $('.woocommerce').on('change', 'input.qty', function(){

        if ( timeout !== undefined ) {
            clearTimeout( timeout );
        }

        timeout = setTimeout(function() {
            $("[name='update_cart']").trigger("click");
        }, 500 );
    });
} );